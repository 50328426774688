import { useMemo } from 'react'
import { useSiteMetadata } from '../../../hooks/useSiteMetadata'
import { getRelatedDemoThumbnail } from '../../../utils/demo_helpers'
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs'
import DemosLayout from '../../../components/DemosLayout/DemosLayout'
import ContentSidebar, {
  ILinksListItem,
} from '../../../components/ContentSidebar/ContentSidebar'
import ItemsCarousel from '../../../components/ItemsCarousel/ItemsCarousel'
import { ContentType } from '../../../context/GenericSearchContext/GenericSearchContext.constants'
import { useRelatedContentQuery } from '../../../providers/ApolloProvider/GlassOnion/hooks'

type IndividualDemoLayoutProps = {
  title: string
  slug: string
  categories: Queries.Frontmatter['categories']
  hardware?: Queries.Frontmatter['hardware']
  curatedRelatedContent: {
    link: string
    slug: string
    thumbnail: string
    title: string
    image: string
  }[]
  mainSection: JSX.Element
  linksList: ILinksListItem[]
  downloads: {
    python: string
    notebook: string
    github: string
  }
  BibTex: string
}

const IndividualDemoLayout = ({
  title,
  slug,
  categories,
  hardware,
  curatedRelatedContent,
  mainSection,
  linksList,
  downloads,
  BibTex,
}: IndividualDemoLayoutProps) => {
  const { data: relatedContent, loading: isLoadingRelatedContent } =
    useRelatedContentQuery(slug, ContentType.DEMO, [ContentType.DEMO])

  const { siteUrl } = useSiteMetadata()
  // COBINE AND RETURN CONTENT BASED ON USE-CASE
  const { relatedCarouselContent, relatedSidebarContent } = useMemo(() => {
    const curated = curatedRelatedContent.map((relatedContent) => ({
      label: relatedContent.title || '',
      link: `/qml/demos/${relatedContent.slug}`,
      image: getRelatedDemoThumbnail(relatedContent.image || '', siteUrl),
      slug: relatedContent.slug,
    }))
    let glassOnion: {
      label: string
      link: string
      image: string
      slug: string
    }[] = []

    if (!isLoadingRelatedContent && relatedContent?.content?.related) {
      glassOnion = relatedContent?.content?.related.map((related) => ({
        label: `${related.title}` || '',
        link: related.link || '',
        image: related.thumbnail || '',
        slug: related.slug || '',
      }))
    }

    const combined = [...curated, ...glassOnion].filter((item, index, self) => {
      return index === self.findIndex((t) => t.slug === item.slug)
    })

    return {
      relatedCarouselContent: combined,
      relatedSidebarContent: combined.slice(0, 3),
    }
  }, [relatedContent, isLoadingRelatedContent, curatedRelatedContent])
  const hasRelatedContent = relatedCarouselContent.length > 0

  return (
    <>
      <Breadcrumbs pageTitle={title} categories={categories?.map((c) => c)} />
      <DemosLayout
        mainSection={mainSection}
        sidebarSection={
          <ContentSidebar
            linksList={linksList}
            downloads={downloads}
            related={relatedSidebarContent}
            BibTex={BibTex}
            hardware={hardware}
            isLoadingRelatedContent={isLoadingRelatedContent}
            contentTitle={title}
          />
        }
      />
      {hasRelatedContent && (
        <div className="RelatedItemsSection">
          <ItemsCarousel
            relatedItems={relatedCarouselContent}
            title="Related Demos"
            slidesToShow={3}
            responsive={[
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: 606,
                settings: {
                  slidesToShow: 1,
                },
              },
            ]}
          />
        </div>
      )}
    </>
  )
}

export default IndividualDemoLayout
